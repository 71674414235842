import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { defaultLangKey } from '../data/languages';
import { seoData } from '../data/seo';
import { models } from '../../models';
import ContentWidth from '../components/contentWidth';

type Props = {
  location: Location;
};

const NotFoundPage = ({ location }: Props) => (
  <Layout
    lang={defaultLangKey}
    location={location}
    pathnameWithoutLang={models.pages!.notFound.path}
  >
    <SEO
      featuredImage={seoData.notFound.featuredImage?.[defaultLangKey]}
      title={seoData.notFound.title[defaultLangKey]}
      description={seoData.notFound.description[defaultLangKey]}
      lang={defaultLangKey}
      pathname={location.pathname}
    />
    <ContentWidth>
      <section className="not-found">
        <div className="container">
          <div className="inner">
            <div className="not-found__desc">
              <p>Sorry, but the page you requested is not found.</p>
            </div>
          </div>
        </div>
      </section>
    </ContentWidth>
  </Layout>
);

export default NotFoundPage;
